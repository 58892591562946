const { event } = require("jquery");

/**
 * resetForm
 * This function will reset the company form fields
*/ 
window.resetForm =  function resetForm() {
    $('#create_company_name').val('');
    $('#create_contact_name').val('');
    $('#create_contact_email').val('');
    $('#create_contact_phone').val('');
    $('#create_territories').val('');
    $('#create_active_user_limit').val('');
    $('#create_subscription_start').val('');
    $('#create_subscription_end').val('');
    $('.invalid-feedback').hide();
    document.getElementById('editCompanyForm').reset();
}

/**
 this code is reusable 
 and for validation of input fields
 */

 function sanitizeInput(input, allowSpecialCharacters = false) {
    input = input.replace(/<script.*?>|<\/script>|on\w+=".*?"/gi, '');
    if (!allowSpecialCharacters) {
        input = input.replace(/[^a-zA-Z0-9\s]/g, '');
    }
    return input;
}

function addInputEventListener(inputId, allowSpecialCharacters = false) {
    const input = document.getElementById(inputId);
    input.addEventListener('input', function() {
        this.value = sanitizeInput(this.value, allowSpecialCharacters);
    });
}

/**
 * Get Company Details
 * Request Url :- '/admin/company-json/'
 * It will fill the company fields what we get from api response
*/ 
function updateCompnayFormValidarion() {
    addInputEventListener('name');
    addInputEventListener('contact_email',true);
    addInputEventListener('contact_name');
    addInputEventListener('territories');
}
$(document).ready(function () {
    $('.edit-company-link').unbind('click').click(function () {
        updateCompnayFormValidarion()
        var companyId = $(this).attr("data-id");
        var editCompanyForm = $('#editCompanyForm');
        var messageContainer = $('#messageContainer');
        var loading = $('#loadingSpinner');
        var loadingbtn = $('#editcompanyloadingSpinner');
        var footer = $('#footer');
        loadingbtn.hide();
        loading.show();
        editCompanyForm.hide();
        footer.hide();
        messageContainer.text('').hide();
        $.ajax({
            url: '/admin/company-json/' + companyId,
            method: 'GET',
            success: function (response) {
                loading.hide();
                editCompanyForm.show();
                footer.show();
                if (response.company) {
                    editCompanyForm
                        .find('input[name="name"]')
                        .val(response.company.name);
                    editCompanyForm
                        .find('input[name="contact_name"]')
                        .val(response.company.contact_name);
                    $('#editCompanyModal').modal('show');
                    // Populate the modal fields with company details
                    $('#editCompanyId').val(response.company.id);
                    $('#name').val(response.company.name);
                    $('#contact_name').val(response.company.contact_name);
                    $('#contact_email').val(response.company.contact_email);
                    $('#contact_phone').val(response.company.contact_phone);
                    $('#territories').val(response.company.territories);
                    $('#active_user_limit').val(
                        response.company.active_user_limit
                    );
                    $('#subscription_start').val(
                        response.company.subscription_start_date?.split(' ')[0]
                    );
                    $('#subscription_end').val(
                        response.company.subscription_end_date?.split(' ')[0]
                    );

                    if (response.company.active) {
                        $('#remember').prop('checked', true);
                    } else {
                        $('#remember').prop('checked', false);
                    }
                    // Show the modal
                    $('#editCompanyModal').modal('show');
                    messageContainer.hide();
                } else {
                    messageContainer.text('Company not found.').show();
                }
            },
            error: function (error) {
                messageContainer.text('Error fetching company details.').show();
            },
        });
    });
    // Rest of the code remains the same for updating the company (see the previous response).
});

/**
 * Edit Company Details
 * Request Url :- '/admin/company-json/${companyId}/edit'
 */ 
$(document).ready(function () {
    $('.edit-company-button').unbind('click').click(function () {
        var loading = $('#editcompanyloadingSpinner');
        var companyId = $('#editCompanyId').val();        
        var editCompanyForm = $('#editCompanyForm');
        var messageContainer = $('#messageContainer');
        $('[name]').removeClass('is-invalid');
        $('.invalid-feedback').remove();
        messageContainer.text('').hide();
        // Serialize the form data
        var formData = editCompanyForm.serialize();
        loading.show();
        $.ajax({
            url: `/admin/company-json/${companyId}/edit`,
            method: 'PUT',
            data: formData,
            success: function (response) {
                loading.hide();
                $('[name]').removeClass('is-invalid');
                $('.invalid-feedback').remove();
                if ('error' in response) {
                    messageContainer.text('').hide();
                    // Display validation error messages in the form fields
                    $.each(response.error, function (key, value) {
                        var inputField = $('[name="' + key + '"]');
                        inputField.addClass('is-invalid');
                        inputField
                            .parent()
                            .append(
                                '<span class="invalid-feedback" role="alert"><strong>' +
                                    value[0] +
                                    '</strong></span>'
                            );
                    });
                } else if ('message' in response) {
                    messageContainer
                        .removeClass('alert-danger')
                        .addClass('alert-success')
                        .text(response.message)
                        .show();
                    setTimeout(() => {
                        location.reload();
                    }, 3000);
                }
            },
            error: function (error) {
                loading.hide();

                // Clear previous error messages
                messageContainer.text('').hide();

                // Display validation error messages in the form fields
                $.each(error.responseJSON.error, function (key, value) {
                    var inputField = $('[name="' + key + '"]');
                    inputField.addClass('is-invalid');
                    inputField
                        .parent()
                        .append(
                            '<span class="invalid-feedback" role="alert"><strong>' +
                                value[0] +
                                '</strong></span>'
                        );
                });
            },
        });
    });
});

/**
 * Create Company
 * Request Url :- '/admin/company-json/store' 
 */ 

function createNewCompnayFormValidarion() {
    addInputEventListener('create_company_name');
    addInputEventListener('create_contact_email',true);
    addInputEventListener('create_contact_name');
    addInputEventListener('create_territories');
}
$(document).ready(function () {
    var messageContainer = $('#create_messageContainer');
    var loading = $('#createcompanyloadingSpinner');
    $('#createCompanyModal').on('show.bs.modal', function () {
        loading.hide();
        messageContainer.hide().text('');
        $('[name]').removeClass('is-invalid');
        $('.invalid-feedback').remove();
        createNewCompnayFormValidarion();
    });
    $('.create-company-button').unbind('click').click(function () {
        messageContainer.text('').hide();
        // Get the form data
        var formData = {
            _token: $('input[name=_token]').val(),
            name: $('#create_company_name').val(),
            contact_name: $('#create_contact_name').val(),
            contact_email: $('#create_contact_email').val(),
            contact_phone: $('#create_contact_phone').val(),
            territories: $('#create_territories').val(),
            active_user_limit: $('#create_active_user_limit').val(),
            subscription_start: $('#create_subscription_start').val(),
            subscription_end: $('#create_subscription_end').val(),
        };
        loading.show();
        // Send an AJAX POST request
        $.ajax({
            type: 'POST',
            url: '/admin/company-json/store',
            data: formData,
            success: function (response) {
                // Handle the JSON response here
                loading.hide();
                $('[name]').removeClass('is-invalid');
                $('.invalid-feedback').remove();
                if ('error' in response) {
                    if (typeof response.error === 'string') {
                        messageContainer
                            .removeClass('alert-success')
                            .addClass('alert-danger')
                            .text(response.error)
                            .show();
                    } else {
                        messageContainer.text('').hide();
                        // Display validation error messages in the form fields
                        $.each(response.error, function (key, value) {
                            var inputField = $('[name="' + key + '"]');
                            inputField.addClass('is-invalid');
                            inputField
                                .parent()
                                .append(
                                    '<span class="invalid-feedback" role="alert"><strong>' +
                                        value[0] +
                                        '</strong></span>'
                                );
                        });
                    }
                } else if ('message' in response) {
                    messageContainer
                        .removeClass('alert-danger')
                        .addClass('alert-success')
                        .text(response.message)
                        .show();
                    setTimeout(() => {
                        $('#createCompanyModal').modal('hide');
                        location.reload();
                    }, 3000);
                }
            },
            error: function (xhr, status, error) {
                loading.hide();
                alert('An error occurred: ' + error);
            },
        });
    });
});


// show blade php jquery

/**
 * Create User 
 * Request Url :- '/company/{id}/user/store'
 */ 
function createNewUserFormValidarion() {
    addInputEventListener('first_name');
    addInputEventListener('email',true);
    addInputEventListener('last_name');
}
$(document).ready(function () {
    var loading=$('#createuserloadingSpinner')
    var messageContainer = $('#createuser_messageContainer');
    $('#createUserModal').off('show.bs.modal').on('show.bs.modal', function () {
        loading.hide()
        messageContainer.hide().text('');
        $('[name]').removeClass('is-invalid');
        $('.invalid-feedback').remove();
        createNewUserFormValidarion();
    });
    $('#createUserBtn').unbind('click').click(function () {
        loading.show()
        $.ajax({
            url: $('#createUserForm').data('url'),
            type: 'POST',
            data: $('#createUserForm').serialize(),
            success: function (response) {
                // Handle success
                loading.hide()
                $('[name]').removeClass('is-invalid');
                $('.invalid-feedback').remove();
                
                if ('error' in response) {
                    if (typeof response.error === 'string') {
                        messageContainer.removeClass('alert-success').addClass(
                            'alert-danger').text(response.error).show();
                    } else {
                        messageContainer.text('').hide();
                        // Display validation error messages in the form fields
                        $.each(response.error, function(key, value) {
                            var inputField = $('[name="' + key + '"]');
                            inputField.addClass('is-invalid');
                            inputField.parent().append(
                                '<span class="invalid-feedback" role="alert"><strong>' +
                                value[0] + '</strong></span>');
                        });
                    }

                } else if ('message' in response) {
                    loading.hide()
                    messageContainer.removeClass('alert-danger').addClass(
                        'alert-success').text(response.message).show();
                        setTimeout(() => {
                            window.location.href = response.redirect_url;
                    $('#createUserModal').modal('hide');
                    location.reload();
                        }, 3000);
                        
                }

            },
            error: function (xhr, status, error) {
                loading.hide()
                messageContainer.removeClass('alert-success').addClass(
                            'alert-danger').text(error).show();
                alert('Error creating user. Please try again.');

            }
        });
    });
});

/**
 * Get User Details
 * Request Url :- `/admin/company/user/${userId}/details`
 */ 
function updateUserFormValidarion() {
    addInputEventListener('edit_first_name');
    addInputEventListener('edit_email',true);
    addInputEventListener('edit_last_name');
}
$(document).ready(function () {
    $('#editUserModal').off('show.bs.modal').on('show.bs.modal', function (event) {
        updateUserFormValidarion();
        const userId = $(event.relatedTarget).attr('data-id');
        $('#edit_first_name').val('');
        $('#edit_last_name').val('');
        $('#edit_email').val('');
        $('#edit_userId').val('');
        $('#updateuser_messageContainer').hide().text('');
        $('[name]').removeClass('is-invalid');
        $('.invalid-feedback').remove();
        $('#editloadingSpinner').show();
        $('#editloadingSpinnerbutton').hide();
        $('#editUserForm, #updateUserBtn').hide();

        $.ajax({
            url: `/admin/company/user/${userId}/details`,
            type: 'GET',
            success: function (response) {
                $('#edit_first_name').val(response.user.first_name);
                $('#edit_last_name').val(response.user.last_name);
                $('#edit_email').val(response.user.email);
                $('#edit_userId').val(response.user.id);
                $('#editUserForm, #updateUserBtn').show();
                $('#editloadingSpinner').hide();
            },
            error: function (xhr, status, error) {
                alert('Error fetching user details. Please try again.');
                $('#editloadingSpinner').hide();
            }
        });
    });
});


/**
 * Delete User
 * Request Url :- '/admin/company/' + companyId + '/user/' + userId'
 */ 
$(document).ready(function () {
    var messageContainer = $('#updateuser_messageContainer');
    $("#deleteUserBtn").unbind('click').click(function (e) {
        e.preventDefault();
        var userId = $('#edit_userId').val();
        var companyId = $('#show_blade_company').attr("data-id")
        $.ajax({
            type: 'POST',
            url: '/admin/company/' + companyId + '/user/' + userId,
            data: {
                '_token': $('input[name=_token]').val(),
            },
            success: function (data) {
                messageContainer.removeClass('alert-danger').addClass(
                        'alert-success').text(data.message).show();
                setTimeout(()=>{
                    location.reload();
                },3000)
            },
            error: function (xhr, status, error) {
                alert('An error occurred: ' + error);
            }
        });
    });
});

/**
 * Edit User
 * Request Url :- '/admin/user/update/${companyId}/${userId}'
 */
$(document).ready(function () {
    $('#updateUserBtn').unbind('click').click(function (e) {
        var messageContainer = $('#updateuser_messageContainer');
        var loading=$('#editloadingSpinnerbutton')
        e.preventDefault();
        var companyId = $('#show_blade_company').attr("data-id")
        var userId = $('#edit_userId').val();
        var form = $('#editUserForm');
        var url = form.data('url');
        var data = form.serialize();
        messageContainer.hide().text('');
        loading.show()
        $.ajax({
            type: 'PUT',
            url: `/admin/user/update/${companyId}/${userId}`,
            data: data,
            success: function (response) {
                // Handle success, for example, show a success message or close the modal
                loading.hide()
                $('[name]').removeClass('is-invalid');
                $('.invalid-feedback').remove();
                if ('error' in response) {
                    if (typeof response.error === 'string') {
                        messageContainer.removeClass('alert-success').addClass(
                            'alert-danger').text(response.error).show();
                        displayAlert(response.error, 'alert-danger');
                    } else {
                        messageContainer.text('').hide();
                        // Display validation error messages in the form fields
                        $.each(response.error, function(key, value) {
                            var inputField = $('[name="' + key + '"]');
                            inputField.addClass('is-invalid');
                            inputField.parent().append(
                                '<span class="invalid-feedback" role="alert"><strong>' +
                                value[0] + '</strong></span>');
                        });
                    }

                } else if ('message' in response) {
                    messageContainer.removeClass('alert-danger').addClass(
                        'alert-success').text(response.message).show();
                        setTimeout(() => {
                            $('#editUserModal').modal('hide');
                            location.reload(); 
                        }, 3000);
                        
                }

                
            },
            error: function (error) {
                // Handle error, for example, show an error message
                console.error(error);
            }
        });
    });
});

// login page captcha
window.correctCaptcha = function(response) {
    var recaptchaInput = document.getElementById('captcha-response');
    recaptchaInput.value = response;
    
    return response;
};

// password field no copy paste logic
window.onload = () => {
    if (window.location.pathname === '/login') {
        const myInput = document.getElementById('password');
        if (myInput) {
            myInput.onpaste = (e) => e.preventDefault();
        }
    }
};
